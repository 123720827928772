import React from "react"
import Navbar from "../components/navbar"
import Container from "../components/container"
import Header from "../components/header"
import Layout from "../components/layout"
import BackgroundShape from "../components/backgroundShape"
import Head from "../components/head"


export default function PrivacyCandidate () {

    return (
      <Layout navbar="transparent">
        <Head title="Privacy Policy of the Onport Platform" description="The Privacy Policy of the Onport Platform"/>
         
        <BackgroundShape style={{shape: "clip-fixed-small", color: "bg-privacy-sm", particles: false}}/>
        <Container style={{classes: "container" }}>
          <div>
            <Header content={{title: "Onport Unite Privacy Policy", text: ""}} style={{color: "text-center"}}/>
          </div>
        </Container>
        <Container style={{classes: "container privacy mb-5" }}>

        <h2>1. Data Collection for the Onport Unite Program</h2>
          <p>As part of our Onport Unite program, we may collect and process certain types of personal and non-personal data to facilitate connections between marketplaces and sellers within our network. This data collection enables us to optimize your experience and offer tailored opportunities for your business.</p>
          <ul>
              <li>
                  <p><strong>Personal Information Collected:</strong> We collect information such as your name, business contact details, and transaction history to facilitate meaningful connections between marketplaces and sellers.
                  </p>
              </li>
              <li>
                  <p><strong>Non-personal Information Collected:</strong> We may collect non-identifiable information such as browsing activity, preferences, and interactions with our platform to provide personalized product suggestions and relevant seller recommendations.</p>
              </li>
          </ul>
          <h2>2. Purpose of Data Collection</h2>
          <p>The data collected through the Onport Unite program is used for the following purposes:</p>
          <ul>
              <li>
                  <p><strong>Connecting with Sellers:</strong> We use your information to match marketplaces with sellers whose products align with their business needs, product categories, and growth objectives.</p>
              </li>
              <li>
                  <p><strong>Expanding Opportunities:</strong> By analyzing marketplace catalogues in Onport's platform, we aim to create opportunities for both marketplaces and sellers to expand and increase sales. Marketplaces will be able to increase their catalogues and Sellers also gain visibility in more marketplaces, increasing their ability to sell through multiple channels.</p>
              </li>
              <li>
                  <p><strong>Enhancing Personalization:</strong> We strive to improve both marketplaces and sellers experience by tailoring seller recommendations based on the activity on our platform.</p>
              </li>
          </ul>
          <h2>3. Sharing Data with Third Parties</h2>
          <p>To fully benefit from the Onport Unite program, your data may be shared with trusted third-party sellers, marketplaces and partners within our network. The data shared is limited to the information necessary to establish meaningful connections and facilitate transactions.</p>
          <ul>
              <li>
                  <p><strong>Who We Share Data With:</strong> Personal information from marketplaces may be shared with sellers within the Onport Unite network to help them offer relevant products or services. Similarly, personal information from sellers may be shared with marketplaces to assist them in listing products on additional e-commerce platforms. We ensure that only the necessary data is shared for these purposes, and it is done in a secure and controlled manner.</p>
              </li>
              <li>
                  <p><strong>How Sellers and Marketplaces Handle Data:</strong> All sellers and marketplaces participating in the Onport Unite program must comply with stringent data privacy standards. They are permitted to use your data only for fulfilling transactions, listing products, or delivering services, and must implement protective measures to prevent unauthorized access or misuse.</p>
              </li>
          </ul>
          <h2>4. Consent and Control</h2>
          <p>Your participation in the Onport Unite program is entirely optional. We prioritize your control over how your data is used and offer clear options for you to manage your preferences.</p>
          <ul>
              <li>
                  <p><strong>Opt-in Consent:</strong> You must provide explicit consent before we share your data with third-party sellers or marketplaces in the Onport Unite network. Without your consent, your data will remain private and will not be shared beyond Onport’s platform.</p>
              </li>
              <li>
                  <p><strong>Opt-out Option:</strong> If you no longer wish to participate in the Onport Unite program, you can opt out at any time by contacting us at <a target="_blank" rel="noopener noreferrer nofollow" href="mailto:help+onportunite@onport.com">help+onportunite@onport.com</a>.</p>
              </li>
          </ul>
          <h2>6. International Data Transfers</h2>
          <p>If your participation in the Onport Unite program involves sellers and marketplaces located in different regions, we may transfer your data across borders. We ensure that any international data transfers comply with applicable data protection regulations.</p>
          <h2>7. Updates to Our Privacy Policy</h2>
          <p>As we continue to develop the Onport Unite program, we may need to update our privacy policy to reflect any changes in our data practices. We are committed to keeping you informed.</p>
          <ul>
              <li>
                  <p><strong>Notification of Changes:</strong> If we make significant updates to this privacy policy, including changes related to the Onport Unite program, we will notify you via email or through an announcement on our Onport app. The latest version of our privacy policy will always be available on our platform.</p>
              </li>
          </ul>        
</Container>
      </Layout>
    )
}
